
.contact{
  font-family: 'Montserrat', sans-serif;
  max-width: 1366px;
  margin: auto;
  background:url(../img/marcos/fondo\ metal.jpg)no-repeat center center/cover;
  margin-top: 80px;
  height: 100%;
  margin-bottom: 100px;
  
}


.contact_img img{
  width: 100%;
  
}






.contact_title{
  text-align: center;
  font-size: 35px;
  /*  background-color:  rgba(0, 5, 77, 0.658); */
  /* background-color:  rgba(1, 7, 44, 0.63); */
    box-shadow: 2px 2px 5px rgb(49, 48, 99);
    /* color: rgb(102, 102, 102); */
    color: #ddd;
    
    padding: 20px;
    position: relative;
    border-radius: 10px;
    font-size: 40px;
    max-width: 1366px;
    margin: auto;
    margin-bottom: 50px;  
    margin-top: 50px;    
  }

  
  .contact_flex{
    display: flex;
    /*background-color:  #021b55bb;; */
    /* background-color:  rgba(0, 0, 0, 0.76); */
    /* background-color:  rgba(1, 7, 44, 0.63); */
    box-shadow: 2px 2px 5px rgb(69, 68, 102);
    justify-content: center;
    max-width: 1366px;
    margin: auto;
    line-height: 1.2em; 
    padding: 40px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    font-size: 1.1em;
    color: white;
    padding-bottom: 50px;
    
    
    
    
    
  }
  .contact_left{
    width: 100%;
    /* color: rgb(102, 102, 102); */
    color: #ddd;
    background-color: rgba(0,0,0,0.2);
    border-radius: 5px;
    padding: 20px;
    
    
    
  }
  .contact_right{
    width: 100%; 
    /* color: rgb(102, 102, 102); */
    padding: 20px;
    
    color: #ddd;
 
}





/* .contact .container--flex{
  align-items: flex-start;
} */


.contact-information{
  padding:10px;
  padding-top: 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #DE423A;
}




.contact-information [class*="icon-"]{
  font-size: 1.3em; 
  margin-right: 7px;
  position: relative;
  top: 5px;
}

.contact-information .social-icon{
  justify-content: flex-start;
  margin-bottom: 10px;
}

.contact-information .social-icon [class*="icon-"]{
  background:black;
  
  margin-left: 0;
  margin-right: 15px;
  
}



.formulario__label, .formulario__input-txt, .formulario__textarea{
  display:block;
  width:100%;
}

.formulario__input-txt{
  padding:10px;
  margin-bottom:10px;
}

.formulario__textarea{
  min-height: 100px;
  min-width: 100%;
  max-width: 100%;
  max-height: 200px;
  padding: 10px;
}

.formulario__btn{
  border:none;
  cursor: pointer;
  font-size: 1em;
  padding: 10px 20px;
  margin-top: 20px;
}


@media(max-width:768px){
  .contact_flex{
    
    
    
  flex-direction: column;
    width: 100%;
    margin: auto;
    line-height: 1.2em; 
    padding: 30px;
   
    height: 100%;
    z-index: 1000;
   border-radius: 0;
    height: 100%;
  }
     
    .contact_left{
        margin-bottom: 100px;
    }
    .contact_img::before {
      
      height: 300px;
      
    }
    .contact_img {
      
      height: 300px;
      
    }





    .contact_title{
      text-align: center;
      border-radius: 0;
    }
}

.formulario_btn{
    background-color: rgba(0,0,0,0.5);
    width: 100px;
    color: white;
}
.homeCarousel img{
    height: 400px;
    object-fit: cover;
    position: relative;
 }

.homeCarousel{
    position: absolute;   
    width: 100%;
    height: 400px;
    overflow: hidden;
    box-shadow: 2px 2px 5px #999; 
    
    
}

.carousel-control-prev, .carousel-control-next{
    display: none;
}

.homeCarousel a{
    display: none;
}


.homeCarousel::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
     /* background: #031d589d; */
     background-color: rgba(0, 14, 92, 0.877);
    z-index: 1;
}
@media(max-width:768px){
    .homeCarousel{
        width: 100%;
        height: 300px;
        object-fit: cover;
            
    }
    
    .homeCarousel{
        overflow: hidden;
      
            
    }
    

}

.herramientaVehicleService img{
    width: 225px;
    
    transition: all .7s;
    border-radius: 5px;
    margin:20px;
    margin-bottom: 30px;
    margin-top: 100px;
}
.herramientaVehicleService img:hover{
   transform: scale(1.5);
   transition: all .7s;
}


.herramientaVehicleService{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
    margin: auto;
    justify-content: space-between;

    max-width: 1200px;
    margin: auto;
    


    
}
@media(max-width:768px){
    .herramientaVehicleService img{
        width: 122px;
        margin:0;
        margin-bottom: 10px;
        margin-top: 90px;
     }
}
.puntas img{
    width: 225px;
  
    transition: all .7s;
    border-radius: 5px;
    margin:20px;
    margin-bottom: 30px;
    margin-top: 100px;
}
.puntas img:hover{
   transform: scale(1.5);
   transition: all .7s;
}


.puntas{
    display: flex;
 
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: auto;
    justify-content: space-between;

}

@media(max-width:768px){
    .puntas img{
        width: 122px;
        margin:0;
        margin-bottom: 10px;
        margin-top: 90px;
     }
}
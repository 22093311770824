.herramientaElectricaCable img{
    width: 225px;
    transition: all .7s;
    border-radius: 5px;
    margin:20px;
    margin-bottom: 30px;
    margin-top: 100px;
}
.herramientaElectricaCable img:hover{
   transform: scale(1.5);
   transition: all .7s;
}

.herramientaElectricaCable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: auto;
    text-align: center;
}
.herramientaElectricaCable a{
    text-decoration: none;
}

@media(max-width:768px){
    .herramientaElectricaCable img{
        width: 122px;
        margin:0;
        margin-bottom: 10px;
        margin-top: 90px;
     }
 
}
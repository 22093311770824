.accesorios img{
    width: 205px;
    transition: all .7s;
    border-radius: 5px;
    margin:20px;
    margin-bottom: 30px;
    margin-top: 100px;
}
.accesorios img:hover{
   transform: scale(1.5);
   transition: all .7s;
}


.accesorios{
    display: flex;
    text-align: center;
    
    flex-wrap: wrap;

    max-width: 1200px;
    margin: auto;
    justify-content: space-between;

}

.accesorios a{
text-decoration: none;

}

@media(max-width:768px){
    .accesorios img{
        width: 122px;
        margin:0;
        margin-bottom: 10px;
        margin-top: 90px;
     }
}
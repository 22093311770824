.equipos_recubrimiento img{
    width: 205px;
    transition: all .7s;
    border-radius: 5px;
    margin:20px;
    margin-bottom: 30px;
    margin-top: 100px;
}
.equipos_recubrimiento img:hover{
   transform: scale(1.5);
   transition: all .7s;
}


.equipos_recubrimiento{
    display: flex;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;

    max-width: 1200px;
    margin: auto;
    justify-content: space-around;

}
.equipos_recubrimiento a{
text-decoration: none;

}

@media(max-width:768px){
    .equipos_recubrimiento img{
        width: 122px;
        margin:0;
        margin-bottom: 10px;
        margin-top: 90px;
     }
}